.about-hero {
    height: 320px !important;
    min-height: 320px;
    padding: 32px 0;
    background-image: url("./../../images/about_bg.png");
}

.about-hero .hero_wrapper {
    align-items: center;
    position: relative;
}
.about-hero .hero_content .text {
    position: static;
}
.about-hero .hero_content {
    padding: 0 30px;
}

@media (max-width: 767.98px) {
    .about-hero .hero_content h1 {
        font-size: 40px;
        line-height: 49px;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        font-size: 28px;
    }
    .about-hero {
        height: 180px !important;
        min-height: 190px;
        padding: 32px 0;
        background-image: url("./../../images/about_bg.png");
    }    
}

.about {
    padding: 120px 0;
}
.about .container {
    display: flex;
    gap: 40px;
}

.about .text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #121212;
}

.about .text span {
    color: #FF512F;
}

.about .text h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 64px;
    text-align: right;
    margin-bottom: 12px;
}

.about .text p {
    font-size: 19px;
    font-weight: 600;
    line-height: 36.53px;
    text-align: right;
}

.about .container .img {
    max-width: 650px;
    width: 100%;
    position: relative;
    padding: 32px;
}
.about .container .img img {
    width: 100%;
    height: 100%;
}
.about .container .img svg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform: scale(1.1);
    z-index: -1;
}

.about .container .img svg:first-of-type {
    transform: translate(32px, 32px);
}
.about .container .img svg:last-of-type {
    transform: translate(-32px, -32px);
}

@media (max-width: 992.98px) {
    .about {
        padding: 80px 0;
    }
    .about .container {
        display: flex;
        gap: 24px;
    }
    .about .text h1 {
        font-size: 35px;
        font-weight: 700;
        line-height: 54px;
        text-align: right;
        margin-bottom: 12px;
    }
    .about .text p {
        font-size: 19px;
        font-weight: 600;
        line-height: 36.53px;
        text-align: right;
    }
    .about .container .img {
        max-width: 650px;
        width: 100%;
        position: relative;
        padding: 16px;
    }
}

@media (max-width: 767.98px) {
    .about {
        padding: 60px 0;
    }
    .about .container {
        display: flex;
        gap: 10px;
        flex-direction: column-reverse;
    }
    .about .container .img {
        max-width: 650px;
        width: 100%;
        position: relative;
        padding: 32px;
    }
}

@media (max-width: 575.98px) {
    .about .container .img svg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: scale(1);
        z-index: -1;
    }
    .about .container .img {
        max-width: 650px;
        width: 100%;
        position: relative;
        padding: 24px;
    }
    .about .text h1 {
        font-size: 28px;
        font-weight: 700;
        line-height: 24px;
        text-align: right;
        margin-bottom: 12px;
    }
    .about .text h1 {
        font-size: 28px;
        font-weight: 700;
        line-height: 24px;
        text-align: right;
        margin-bottom: 12px;
    }
    .about .container .img svg:first-of-type {
        transform: translate(20px, 20px) scale(0.9);
    }
    .about .container .img svg:last-of-type {
        transform: translate(-20px, -20px) scale(0.9);
    }
}

.testemonials {
    padding: 80px 0;
    background-image: url("./../../images/test_bg.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
}

.testemonials {
    padding: 80px 0;
    background-image: url("./../../images/test_bg.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-top: 24px;
}

.testemonials::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000CC;
}

.testemonials * {
    z-index: 2;
}

.testemonials .container {
    display: flex;
    gap: 40px;
}

.testemonials .container .text {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.testemonials .container h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 54px;
    text-align: right;
    color: #fff;
    margin-bottom: 32px;
}

.testemonials .container .text img {
    width: 100%;
}

.testemonials .images {
    width: 45%;
    position: relative;
    display: flex;
    justify-content: end;
}
.testemonials .images img:first-child{
    width: 60%;
    position: absolute;
    right: 0;
    top: -170px;
    animation: float 3s ease-in-out infinite;
    animation-delay: 1.5s;
}
.testemonials .images img:last-child{
    width: 60%;
    z-index: 22;
    animation: float 3s ease-in-out infinite;
}

@keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @media (max-width: 992.98px) {
    .testemonials .container{
        flex-direction: column;
        gap: 100px;
    }
    .testemonials .container .text {
        width: 100%;
    }
    .testemonials .images {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: end;
        max-width: 410px;
        margin: auto;
    }
    .testemonials .images img:first-child {
        width: 60%;
        position: absolute;
        right: 0;
        top: -80px;
        animation: float 3s ease-in-out infinite;
          animation-delay: 0s;
        animation-delay: 1.5s;
    }
  }

  @media (max-width: 575.98px) {
    .testemonials {
        padding: 40px 0;
        margin-top: 24px;
    }
    .testemonials .container h2 {
        font-size: 28px;
        font-weight: 700;
        line-height: 50px;
        text-align: right;
        color: #fff;
        margin-bottom: 12px;
    }
  }

.our-bliefs {
    padding: 120px 0;
}

.our-bliefs h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    text-align: right;
    color: #FF512F;
    margin-bottom: 60px;
}

.our-bliefs .cards_wrappers_b {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
}
.our-bliefs .cards_wrappers_b >div {
    width: 100%;
    height: 350px;
    border: 8px solid #121212;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 500;
    color: #121212;
    border-radius: 20px;
    transition: all .3s ease-in;
}
.our-bliefs .cards_wrappers_b >div:hover {
    color: #fff;
    background-color: #FF512F;
}

@media (max-width: 992.98px) {
    .our-bliefs .cards_wrappers_b > div {
        width: 100%;
        height: 210px;
        border: 8px solid #121212;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        color: #121212;
        border-radius: 20px;
        transition: all .3s ease-in;
    }
}

@media (max-width: 767.98px) {
    .our-bliefs {
        padding: 60px 0;
    }
    .our-bliefs h1 {
        font-size: 37px;
        font-weight: 700;
        line-height: 40px;
        text-align: right;
        color: #FF512F;
        margin-bottom: 40px;
    }
    .our-bliefs .cards_wrappers_b {
        display: grid;
        grid-template-columns: 1fr;
        gap: 24px;
    }
    .our-bliefs .cards_wrappers_b > div {
        width: 100%;
        height: 210px;
        border: 8px solid #121212;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 28px;
        font-weight: 500;
        color: #121212;
        border-radius: 20px;
        transition: all .3s ease-in;
    }
    .our-bliefs h1 {
        font-size: 37px;
        font-weight: 700;
        line-height: 40px;
        text-align: center;
        color: #FF512F;
        margin-bottom: 30px;
    }
}