.portfolio-hero {
    height: 320px !important;
    min-height: 320px;
    padding: 32px 0;
    background-image: url("./../../images/portfolio_bg.png");
}

.portfolio-hero .hero_wrapper {
    align-items: center;
    position: relative;
}
.portfolio-hero .hero_content .text {
    position: static;
}
.portfolio-hero .hero_content {
    padding: 0 30px;
}

@media (max-width: 767.98px) {
    .portfolio-hero .hero_content h1 {
        font-size: 40px;
        line-height: 49px;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        font-size: 28px;
    }
    .portfolio-hero {
        height: 180px !important;
        min-height: 190px;
        padding: 32px 0;
        background-image: url("./../../images/portfolio_bg.png");
    }    
}


.portfolio_cards .container{
    margin-top: 80px;
    margin-bottom: 80px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    gap: 24px 16px;
}

.portfolio_cards .container img {
    width: 100%;
    height: 360px;
    border-radius: 8px;
    background-color: gray;
    margin-bottom: 8px;
}
.portfolio_cards .container p {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    text-align: right;
}
.portfolio_cards .container h2 {
    font-size: 30px;
    font-weight: 500;
    line-height: 30px;
    text-align: right;
    margin-bottom: 16px;
}

@media (max-width: 992.98px) {
    .portfolio_cards .container {
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 767.98px) {
    .portfolio_cards .container {
        grid-template-columns: 1fr;
        padding: 0 32px;
        margin-top: 32px;
        margin-bottom: 32px;
    }
    .portfolio_cards .container img {
        height: 280px;
    }
    .portfolio_cards .container p {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        text-align: right;
    }
    .portfolio_cards .container h2 {
        font-size: 28px;
        font-weight: 500;
        line-height: 30px;
        text-align: right;
        margin-bottom: 8px;
    }
        
}
