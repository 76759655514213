.services-hero {
    height: 320px !important;
    min-height: 320px;
    padding: 32px 0;
    background-image: url("./../../images/services_bg.png");
}

.services-hero .hero_wrapper {
    align-items: center;
    position: relative;
}
.services-hero .hero_content .text {
    position: static;
}
.services-hero .hero_content {
    padding: 0 30px;
}

@media (max-width: 767.98px) {
    .services-hero .hero_content h1 {
        font-size: 40px;
        line-height: 49px;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        font-size: 28px;
    }
    .services-hero {
        height: 180px !important;
        min-height: 190px;
        padding: 32px 0;
        background-image: url("./../../images/services_bg.png");
    }    
}


.services_cards .container{
    margin-top: 80px;
    margin-bottom: 80px;
}


@media (max-width: 767.98px) {
    .services_cards .container {
        margin-top: 32px;
        margin-bottom: 32px;
    }
        
}

.services_cards .container >h1 {
    font-size: 35px;
    font-weight: 700;
    line-height: 38px;
    text-align: right;
    color: #121212;
    margin-bottom: 80px;
}

.services_cards .card {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 60px;
}

.services_cards .card .img::after {
    content: "";
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    border-radius: 12px;
    background-color: #FF512F;
}
.services_cards .card .img::before {
    content: "";
    width: 100px;
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: 12px;
    border:2px solid #FF512F;
}
.services_cards .card:nth-child(even) .img::before {
    content: "";
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: auto;
    right: auto;
    z-index: -1;
    border-radius: 12px;
    background-color: #FF512F;
}
.services_cards .card:nth-child(even) .img::after {
    content: "";
    width: 100px;
    height: 100px;
    position: absolute;
    bottom: 0;
    top: auto;
    left: auto;
    right: 0;
    z-index: -1;
    background-color: transparent;
    border-radius: 12px;
    border:2px solid #FF512F;
}
.services_cards .card .img {
    height: 280px;
    width: 550px;
    padding: 24px;
    position: relative;
}
.services_cards .card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.services_cards .card h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 56px;
    text-align: right;
    color: #FF512F;
    margin-bottom: 24px;
}

.services_cards ul {
    font-size: 19px;
    font-weight: 500;
    line-height: 27.42px;
    text-align: right;
    display: flex;
    flex-direction: column;
    gap: 8px 60px;
    flex-wrap: wrap;
    height: 200px;
}

@media (max-width: 1199.98px) {
    .services_cards .container > h1 {
        font-size: 32px;
        line-height: 34px;
        margin-bottom: 60px;
    }
    .services_cards .card h2 {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 24px;
    }
    .services_cards ul {
        font-size: 16px;
        line-height: 27.42px;
        gap: 8px 60px;
        height: 180px;
    }
    .services_cards .card .img {
        height: 260px;
        width: 490px;
        padding: 24px;
        position: relative;
    }
    .services_cards .card .img::before ,
    .services_cards .card .img::after {
        width: 80px !important;
        height: 80px !important;
    }
}

@media (max-width: 992.98px) {
    .services_cards .card {
        gap: 32px;
        margin-bottom: 56px;
    }
    .services_cards ul {
        font-size: 13px;
        line-height: 17.42px;
        gap: 8px 30px;
        height: 180px;
    }
    .services_cards .card h2 {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 16px;
    }
    .services_cards .card .img {
        height: 220px;
        width: 380px;
        padding: 16px;
        position: relative;
    }
}

@media (max-width: 767.98px) {
    .services_cards .container > h1 {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 50px;
    }
    .services_cards ul {
        font-size: 13px;
        line-height: 17.42px;
        gap: 8px 10px;
        height: 100px;
        padding-right: 20px;
    }
    .services_cards .card .img {
        height: 230px;
        width: 100%;
        padding: 16px;
        position: relative;
    }
    .services_cards .card {
        gap: 4px;
        margin-bottom: 24px;
        flex-direction: column-reverse;
        width: 100%;
    }
}

