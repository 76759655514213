* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Noto Kufi Arabic", sans-serif;
}

body {
    direction: rtl;
    max-width: 100vw;
    overflow: hidden auto;
}

header {
    max-width: 100vw;
    overflow: hidden;
}

header .nav button {
    top: 30px;
}

.container {
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        width: 1140px;
    }
}
@media (min-width: 1400px) {
    .container {
        width: 1320px;
    }
}

.hero {
    background: url("./../images/hero.png") no-repeat center center;
    background-size: cover;
    height: 100vh;
    min-height: 400px;
    width: 100%;
    padding: 100px 0;
}

.hero img {
    width: 120px;
}

.hero_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    gap: 16px;
    max-width: 1500px;
    margin: auto;
}

.hero_wrapper .nav {
    display: flex;
    flex-direction: column;
    justify-content: start;
    border-left: 1px solid #878787;
    height: 100%;
    padding: 0 32px;
}
.hero_wrapper .nav button {
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin: 0 auto;
    background: #ececec47;
    border-radius: 50%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: fixed;
    z-index: 9999999;
    right: 70px;
    top: 170px;
}

@media (max-width: 1300px) {
    .hero_wrapper .nav button {
        left: auto;
        right: 65px;
        top: 170px;
    }
}

@media (max-width: 1199.99px) {
    .hero_wrapper .nav button {
        left: auto;
        right: 50px;
        top: 170px;
    }
}

@media (max-width: 575.99px) {
    .hero_wrapper .nav button {
        left: auto;
        right: 45px;
        top: 110px;
    }
}

.hero_wrapper .nav button.stick {
    z-index: 999999;
}


.hero .hero_content {
    padding: 0 80px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hero .hero_content h1 {
    font-size: 60px;
    font-weight: 600;
    line-height: 95px;
    text-align: right;
    color: #fff;
}
.hero .hero_content p {
    color: #fff;
    font-size: 28px;
    font-weight: 600;
    text-align: right;
    margin-top: 8px;
}
.hero .hero_content .text {
    position: relative;
    height: max-content;
}
.hero .hero_content a {
    font-size: 18px;
    font-weight: 400;
    padding: 8px 32px;
    color: #F8F8F8;
    border: 2px solid #F8F8F8;
    text-decoration: none;
    /* transition: .3s ease-in all; */
    display: block;
    width: max-content;
    border-radius: 50px;
    margin-top: 56px;
    transition: all .3s ease-in;
}
.hero .hero_content a:hover {
    background-color: #FF512F;
}
.hero .hero_content svg {
    position: absolute;
    left: 100%;
    bottom: 100%;
}

.whatsapp {
    width: 56px;
    height:  56px;
    position: fixed;
    bottom: 32px;
    right: 32px;
    border-radius: 50%;
    overflow: hidden;
    z-index: 99999;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.whatsapp img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.social {
    position: fixed;
    top: 50%;
    transform: translateY(-50%) !important;
    left: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    z-index: 9999;
}

.social a {
    width: 50px;
    height: 50px;
    background: #ececec47;
    padding: 10px;
    border-radius: 50%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.social img {
    width: 100%;
}

.establishment {
    padding: 140px 0;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    z-index: 999;
    height: 100vh;
    position: absolute;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
    max-height: 100vh;
}

.establishment .container {
    background-image: url("./../images/es_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 9999;
    position: relative;
}

.establishment .video_container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9 !important;
}
.establishment .back {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10 !important;
    background-color: #fff;
}

.establishment .video_container video {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.establishment_wrapper{
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.establishment_wrapper >.video_container {
    width: 100%;
    height: 100%;
}


.establishment .container {
    /* display: grid; */
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
}
.establishment .container >div:first-child{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.establishment .container img{
    width: 600px;
}
.establishment .logo {
    position: relative;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
}

.establishment .logo span {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
}

.establishment .logo .video_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    overflow: hidden;
    z-index: -1;    /* opacity: 0; */
}

.establishment .logo video {
    height: 100%;
    object-fit: cover;
}

.establishment .container >div:first-child span{
    font-size: 30px;
    font-weight: 300;
    line-height: 35px;
    color: #121212;
    text-align: right;
    width: 100%;
    display: block;
    z-index: 23;
    position: relative;
}

.establishment .logo-dark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: #fff; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 140px min-content 1fr;
}
.establishment .logo-dark >span{
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
}
.establishment .logo-dark img {
    width: 800px;
    display: block;
    margin-bottom: -1px;
    z-index: 9;
    position: relative;
    z-index: 9;
    transform-origin: 29vw 50%;
}

.establishment h1 {
font-size: 40px;
font-weight: 600;
line-height: 40px;
text-align: right;
margin-bottom: 24px;
color: #FF512F;
}

.establishment p {
font-size: 20px;
font-weight: 700;
line-height: 35px;
text-align: right;
color: #595959;
max-width: 50%;
}

.establishment .text {
    display: flex;
    justify-content: space-between;
    align-items: end;
    position: relative;
    width: 100%;
}


.establishment .text span {
    font-size: 20px;
    font-weight: 700;
    line-height: 40px;
    text-align: right;
    white-space: nowrap;
    color: #121212;    
}

.portfolio .container >img {
    width: 130px;
    display: block;
    margin: auto;
    height: 45px;
    object-fit: cover;
    object-position: top;
}
.portfolio .container >p {
    font-size: 18px;
    font-weight: 500;
    line-height: 32.94px;
    text-align: center;
    color: #BCBCBC;
    display: block;
    margin: auto;
    max-width: 700px;
    margin-bottom: 80px;
}
.portfolio .container >p:last-child {
    margin: 0;
    text-align: right;
    margin-top: 56px;
}
.portfolio {
    padding: 56px 0;
    background-image: url("/src/images/b3733813fd7a6f356ba7ab767a836a14.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.portfolio::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000CC;
}

.portfolio * {
    position: relative;
    z-index: 2;
}

.portfolio .cards_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
}

.portfolio .card {
    width: 100%;
    height: 250px;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    display: block;
    cursor: pointer;
    transition: all .2s ease-out;
}
.portfolio .card .desc{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 38%, rgba(255,81,47,1) 100%);     color: #fff;
    font-size: 14px;
    display: flex;
    align-items: end;
    padding: 16px;
    bottom: 0;
    z-index: 6;
    opacity: 0;
}

.portfolio .card:hover .desc{
    transition:  .3s ease-in all;
    opacity: 1;
}

.portfolio .card::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #ffffff01, #ffffff04),
    linear-gradient(158.03deg, rgba(0, 0, 0, 0) 14.37%, rgba(0, 0, 0, 0.5) 84.27%);
    z-index: 2;
}

.portfolio .card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.portfolio .card p {
    font-size: 35px;
    font-weight: 300;
    line-height: 40px;
    text-align: right;
    color: #DCDCDC;
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 3;
    transition:  .2s ease-in all;
    z-index: 99;
}

.portfolio .card:hover p{
    bottom: 68px;
}


.sponsors {
    padding: 120px 0;
}

.sponsors .swiper {
    width: 100%;
}

.sponsors .swiper-slide {
    width: 120px;
    padding: 0 16px;
}

.sponsors .swiper-slide img{
    width: 100%;
}

.sponsors h1 {
    font-size: 42px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 12px;
    color: #FF512F;
    text-align: center;
}
    
.sponsors p {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #4D4D4D;
    margin-bottom: 60px;
}

footer {
    background-image: url("/src/images/footer.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 80px 0;
}

footer::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000CC;
}

footer * {
    position: relative;
    z-index: 2;
}

footer .container {
    display: flex;
    justify-content: space-between;
    gap: 24px;
}

footer .text img {
    width: 150px;
    height: 60px;
    object-fit: cover;
    object-position: top;
}
footer .text p {
    color: #afafaf;
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
    max-width: 550px;
    text-align: right;
}

footer .socials {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
footer .socials a {
    align-items: center;
    color: #afafaf;
    display: flex;
    flex-direction: row-reverse;
    font-size: 14px;
    font-weight: 400;
    gap: 12px;
    line-height: 18px;
    text-align: left;
    text-decoration: none;
}

footer .socials .download {
    display: flex;
    flex-direction: row;
    gap: 4px;
    color: white;
    justify-content: end;
    margin-top: 24px;
}
footer .socials .download a{
font-size: 16px;
font-weight: 700;
line-height: 18px;
color: #FF512F;
}

header nav {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: black;
    z-index: 9999999999999999;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: start;
    display: none;
}

header nav.open {
    display: flex;
    animation-delay: 1s;
    animation: opacityAni .5s ease-out ;
}
.moveTop {
    animation: moveTop .3s ease-in ;
    opacity: 0;
}
.moveLeft {
    animation: moveLeft .3s ease-in ;
    opacity: 0;
}
.moveRight {
    animation: moveRight .3s ease-in ;
    opacity: 0;
}
@keyframes opacityAni {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
  }

@keyframes moveTop {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
  }

@keyframes moveLeft {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(-100%);
    }
  }

@keyframes moveRight {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(100%);
    }
  }

header nav .links {
    display: flex;
    flex-direction: column;
    font-family: Noto Kufi Arabic;
    font-size: 28px;
    font-weight: 600;
    line-height: 52px;
    text-align: right;
    text-decoration: none;
    color: #F8F8F8;
    margin-bottom: 24px;
    transition: all .3s ease-in;
}

header nav .links a {
    text-decoration: none;
    color: #F8F8F8;
    transition: all .3 ease-in;
}

header nav .links a:hover {
    color: #FF512F;
}

header nav .social_link svg {
    width: 40px;
    margin-left: 24px;
}

header nav button {
    background: transparent;
    border: none;
    color: white;
    font-size: 40px;
    font-weight: 700;
}

@media (max-width: 767.98px) {
    header nav .social_link svg {
        width: 30px;
        margin-left: 16px;
    }
    header nav .links {
        display: flex;
        flex-direction: column;
        font-family: Noto Kufi Arabic;
        font-size: 20px;
        font-weight: 600;
        line-height: 47px;
        text-align: right;
        text-decoration: none;
        color: #F8F8F8;
        margin-bottom: 24px;
        transition: all .3s ease-in;
    }
    header nav button {
        background: transparent;
        border: none;
        color: white;
        font-size: 30px;
        font-weight: 700;
    }
}

.portfolio .card:hover p {
    z-index: 9;
}

@media (max-width: 767.99px) {
    .portfolio .card.active p{
        bottom: 68px;
    }
    .portfolio .card.active .desc{
        transition:  .3s ease-in all;
        opacity: 1;
    }
    .portfolio .card:hover p, .portfolio .card.active p {
        bottom: 48px;
        z-index: 99;
    }
    .portfolio .card .desc {
        padding: 14px;
        bottom: 0;
        z-index: 6;
        opacity: 0;
        font-size: 11px;
        line-height: 19px;
    }
}

footer .socials a svg {
    width: 29px;
}

.Mui-selected {
    color: #ff512f !important;
    border: 1px solid #ff512f !important;
    background-color: #fff !important;
}